import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITheme } from '@common/theme.interface';
import { IProduct } from '@common/product.interface';
import { environment } from '@admin-ui';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}

  async fetchThemes(): Promise<ITheme[]> {
    return this.http.get<ITheme[]>(environment.apis.admin.themeUrl).toPromise();
  }

  async saveTheme(theme): Promise<ITheme> {
    return this.http
      .post<any>(environment.apis.admin.themeUrl, theme)
      .toPromise();
  }

  async updateTheme(theme): Promise<ITheme> {
    return this.http
      .put<any>(`${environment.apis.admin.themeUrl}/${theme._id}`, theme)
      .toPromise();
  }

  async deleteTheme(id: string): Promise<ITheme> {
    return this.http
      .delete<ITheme>(`${environment.apis.admin.themeUrl}/${id}?confirm=${id}`)
      .toPromise();
  }

  async fetchProducts(): Promise<IProduct[]> {
    return this.http
      .get<IProduct[]>(environment.apis.admin.productUrl)
      .toPromise();
  }

  async saveProduct(product): Promise<IProduct> {
    return this.http
      .post<IProduct>(environment.apis.admin.productUrl, product)
      .toPromise();
  }

  async updateProduct(product): Promise<IProduct> {
    return this.http
      .put<IProduct>(
        `${environment.apis.admin.productUrl}/${product._id}`,
        product
      )
      .toPromise();
  }

  async deleteProduct(id: string): Promise<ITheme> {
    return this.http
      .delete<ITheme>(
        `${environment.apis.admin.productUrl}/${id}?confirm=${id}`
      )
      .toPromise();
  }

  async downloadLogs(productId): Promise<any> {
    const requestOptions: Object = {
      responseType: 'text',
    };
    return this.http
      .get<any>(
        `${environment.apis.admin.logsUrl}/product/${productId}`,
        requestOptions
      )
      .toPromise();
  }

  async clearProductLogs(productId: string) {
    return this.http
      .delete<any>(`${environment.apis.admin.logsUrl}/product/${productId}`)
      .toPromise();
  }
  async fetchProdUrlMapping(productUrlMap: string): Promise<IProduct> {
    return this.http.get<IProduct>(
      `${environment.apis.admin.productUrl}/produrlmapping?prodUrlMapping=${productUrlMap}`
    ).toPromise();
  }
}
