export const DEFAULT_TRANSLATIONS = {
  en: {
    registration_title: 'Register',
    product_name: '',
    product_title: '',
    save_btn: 'Save',
    cancel_btn: 'Cancel',
    back_btn: 'Back',
    proceed_btn: 'Proceed',
    try_btn: 'Try Again',
    login_btn: 'Take Me To Log In',
    troubleshoot_btn: 'Troubleshoot Login Issues',
    request_id: 'Request ID:',
    USER_ALREADY_EXISTS:
      'A user already exists with the information entered. Please try logging in with the existing credentials or review the troubleshooting options.',
    INVALID_EMAIL_DOMAIN:
      'Email submitted is not recognized. Please make sure you are using your work email.',
    CANT_CONNECT:
      'A temporary problem is preventing us from fulfilling your registration. Please try again later',
    MISSING_FIRM: 'A firm name needs to be provided.',
    FIRM_NOT_FOUND: 'A firm with the provided name could not be found.',
    FIRM_NOT_ACTIVE: 'A firm with the provided name is not active',
    visit: 'or visit',
    help_section: 'our help section',
    UNKNOWN: 'An unknown error has occurred. Please try again later',
    success_msg:
      'Thank you for registering. You will soon receive two emails, one with your username and the other with your password. You will need your mobile phone to receive a one-time-PIN (OTP) when logging in.',
    success_header: 'Registration Successful',
    error_header: 'Registration Failed',
    temp_user_name: 'Temporary Username',
    confirmation_num: 'Confirmation No',
    privacy_policy: 'Privacy Policy',
    cookie_policy: 'Cookie Policy',
    terms_of_use: 'Terms Of Use',
    error_heading:
      'Please make sure the following fields are properly entered.',
    not_found_msg: 'The page you are trying to access does not exist.',
    not_authorized_msg: 'You are not authorized to view this page.',
    not_found_header: 'Not Found',
    not_authorized_header: 'Not Authorized',
    header_text: '',
    invalid_phonenumber: 'Mobile must be a valid phone number',
    login_form_title: 'User Login', // TODO: Find out if this is needed. Registration Title on line 3 might be intended to be used instead.
    entity_field_title: 'Entity ID/Firm Name',
    username_field_title: 'Username',
    remember_user_id: 'Remember my User ID',
    login_page_cookie_info:
      'Checking "Remember my User ID" will store your username in a cookie on this machine, so that it can be retrieved the next time you visit this site. If you are on a public computer, do not check this box.',
    login_disclaimer:
      'This is an FIS Application environment, which may be accessed and used only for official business by authorized personnel. Unauthorized access or use of this environment is prohibited and may subject violators to administrative, and/or criminal, civil action. Users (authorized or unauthorized) have no explicit or implicit expectation of privacy. All information on this environment may be intercepted, monitored, recorded, read, copied, audited, inspected and disclosed by and to authorized personnel.',
    // for wrux form
    wruxForms_Placeholder: 'Placeholder',
    wruxForms_Prefix: 'Prefix',
    wruxForms_Required: 'This field is required',
    'wruxForms_First Name': 'First Name',
    'wruxForms_Last Name': 'Last Name',
    wruxForms_Email: 'Email Address',
    'wruxForms_Phone Number': 'Phone Number',
    'wruxForms_Mobile Number': 'Mobile Number',
    wruxForms_Submit: 'Submit',
    wruxForms_EmailValidationError: 'Invalid Email Sddress',
    wruxForms_PhoneValidationError: 'Invalid Mobile Number',
    'wruxForms_Country Code': 'Country Code',
    wruxForms_EmptySpaceValidationError: 'Invalid Value',
  },
};
