import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { RufMaterialModule } from '@ruf/material';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

import {
  RufAppCanvasModule,
  RufLayoutModule,
  RufBannerModule,
  RufFooterModule,
  RufIconModule,
  RufMenubarModule,
  RufNavbarModule,
  RufPageHeaderModule,
  RufStatusbarModule,
  RufCardModule,
} from '@ruf/shell';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  HttpClient,
  HttpClientModule,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { AppComponent } from './app.component';
import { LoginflowComponent } from './loginflow/loginflow.component';
import { RedirectService } from './services/redirect.service';
import { environment } from '../environments/environment';
import { UiAdminUiModule } from '../../../../../libs/ui/admin-ui/src/lib/ui-admin-ui.module';
import { AuthGuard, AuthModule } from '@ruf/idp-auth';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ProductService } from './services/product.service';
import { ThemeService } from './services/theme.service';
import { AppVisibilityService } from './services/app-visibility.service';

@NgModule({
  declarations: [AppComponent, LoginflowComponent],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    MatIconModule,
    MatMenuModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      [
        {
          path: 'admin',
          loadChildren: () =>
            import('@ruf/ui/admin-ui').then((module) => module.UiAdminUiModule),
          canActivate: [AuthGuard],
          data: { authorizations: 'CLS_ADMIN' }
        },
        {
          path: '',
          component: LoginflowComponent,
        },
      ],
      { initialNavigation: 'enabled' }
    ),
    RufAppCanvasModule,
    RufLayoutModule,
    RufBannerModule,
    RufFooterModule,
    RufIconModule,
    RufMenubarModule,
    RufNavbarModule,
    RufPageHeaderModule,
    RufCardModule,
    RufLayoutModule,
    RufStatusbarModule,
    RufBannerModule,
    RufFooterModule,
    RufIconModule,
    RufMenubarModule,
    RufNavbarModule,
    RufPageHeaderModule,
    MatButtonModule,
    MatCardModule,
    MatRadioModule,
    RufMaterialModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    AuthModule.forRoot(environment.auth),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ReCaptchaV3Service,
    RedirectService,
    ProductService,
    ThemeService,
    AppVisibilityService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
