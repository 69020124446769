import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  httpOptions: any;

  constructor(private _httpClient: HttpClient, private _router: Router) {
    const token = localStorage.getItem('LOCAL_Token');
    if (token !== null) {
      this.httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + token, //user.jwt,
        }),
      };
    }
  }

  get<T>(url: string): Observable<T> {
    return this._httpClient.get<T>(url, { headers: this.httpOptions }).pipe();
  }

  getWithCustomHeader<T>(url: string, reCaptchaToken: string): Observable<T> {
    return this._httpClient
      .get<T>(url, {
        headers: new HttpHeaders({
          reCaptchaToken: reCaptchaToken,
        }),
      })
      .pipe();
  }

  getWithParams<T>(url: string, params: HttpParams): Observable<T> {
    return this._httpClient
      .get<T>(url, { headers: this.httpOptions, params: params })
      .pipe();
  }

  post<T>(url: string, model: any): Observable<T> {
    return this._httpClient
      .post<T>(url, model, { headers: this.httpOptions })
      .pipe();
  }

  postFormData(url: string, formData: FormData) {
    return this._httpClient
      .post(url, formData, { headers: this.httpOptions })
      .pipe();
  }

  put<T>(url: string, model: any): Observable<T> {
    return this._httpClient
      .put<T>(url, model, { headers: this.httpOptions })
      .pipe();
  }

  delete<T>(url: string, id: string): Observable<T> {
    return this._httpClient
      .delete<T>(url + '/' + id, { headers: this.httpOptions })
      .pipe();
  }

  deleteByQS<T>(url: string): Observable<T> {
    return this._httpClient
      .delete<T>(url, { headers: this.httpOptions })
      .pipe();
  }

  getFile<Blob>(url: string): Observable<Blob> {
    return this._httpClient
      .get<Blob>(url, {
        headers: this.httpOptions,
        responseType: 'blob' as 'json',
      })
      .pipe();
  }
}
