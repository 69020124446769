<!-- <div fxLayout="column" [style.height]="'100vh'"> -->
<!-- <ruf-app-canvas fxFlex class="card-example-canvas clp-canvas" [style.overflow-y]="'auto'"> -->
<!-- <div class="product-branding">
      <div class="logo"></div>
      <ng-container>
        <ng-container *rufIfGtXs="true">
          <div class="seperator"></div>
        </ng-container>
      </ng-container>
    </div> -->
<!-- <div> -->
<div class="container" *ngIf="displayProductToPage()">
  <div class="head-overlay"></div>
  <div class="product-branding">
    <!-- TODO: Add ruf-banner-brand once SDHTML-20730 get resolved -->
    <div class="logo"></div>
    <ng-container *ngIf="displayProduct">
      <ng-container *rufIfGtXs="true">
        <div class="separator"></div>
        <div class="product-name">
          {{ productName }}
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="row">
    <mat-card>
      <div class="cls-container">
        <div class="form-header">
          <h3 id="header">{{loginFormTitle}}</h3>
          <span id="selectedLanguage">{{selectedLangCode}}</span>
          <button mat-icon-button [matMenuTriggerFor]="languageMenu">
            <mat-icon rufIconStyle="sm" fisIcon="link-web"></mat-icon>
            <mat-icon rufIconStyle="sm" fisIcon="arrow-down"></mat-icon>
          </button>
          <mat-menu #languageMenu="matMenu">
            <mat-menu-item *ngFor="let code of languageCodes">
              <button mat-menu-item class="uppercase" (click)="setTranslation(code); setLangPreferenceCookie(code)">{{code}}</button>
            </mat-menu-item>
          </mat-menu>
        </div>

        <!-- <ruf-card fxFlex fisStyle title="Sign In" class="registration-container"> -->
        <form [formGroup]="loginForm" (ngSubmit)="next()">
          <!-- <div class="form-group card-content"> -->
          <div *ngIf="!awaitingResponse && errorVisible">
            <ruf-statusbar fisStyle [closeable]="false" color="error" [border]="false">
              <p class="errStatusBar">
                {{ errorDetails[0].message }}
              </p>
              <ruf-statusbar-icon class="statusBarIcon">
                <mat-icon rufStatusErr fisStyle rufIcon="error-solid" rufIconStyle='md' color="error"></mat-icon>
              </ruf-statusbar-icon>
            </ruf-statusbar>
          </div>

          <div>
            <mat-card-content>
              <mat-form-field fisStyle appearance="outline" boxStyle class="formField">
                <mat-label fisStyle class="inputLabel">{{entityOrFirmFieldTitle}}</mat-label>
                <input autofocus matInput fisStyle [type]="'text'" formControlName="txtEntityId" [(ngModel)]="entityId"
                  autocomplete="off"  />
              </mat-form-field>

              <mat-form-field fisStyle boxStyle appearance="outline" class="formField">
                <mat-label fisStyle class="inputLabel">{{usernameFieldTitle}}</mat-label>
                <input matInput [type]="'text'" formControlName="txtUsername" [(ngModel)]="userName" fisStyle
                  autocomplete="off" />
              </mat-form-field>
            </mat-card-content>
          </div>

          <div class="cls-container-remember-me" style="text-align: center">
            <mat-checkbox class="idp-checkbox" rufId fisStyle color="primary" [(ngModel)] ="remember" [ngModelOptions]="{standalone: true}">{{rememberUserId}}</mat-checkbox>
            <button mat-icon-button fisStyle type="button" [title]=loginCookieInfo>
              <mat-icon fisIcon="help-solid" rufIconStyle="sm"></mat-icon>
            </button>
            <p></p>
          </div>

          <section class="buttonSection">
            <button mat-raised-button fisStyle color="primary" class="btnProceed submit"
              (click)="next" [disabled]="loginForm.invalid || awaitingResponse">{{proceedText}}</button>
            <mat-spinner [diameter]="25" [ngClass]="{'hide-element': !awaitingResponse}"></mat-spinner>
          </section>
          <!-- </div> -->
          <div class="cls-legal-links">
            <a class="link" target="_blank" *ngIf="privacyPolicy.URL" [attr.href]="privacyPolicy.URL">{{privacyPolicy.title}}</a>
            <a class="link" target="_blank" *ngIf="cookiePolicy.URL" [attr.href]="cookiePolicy.URL">{{cookiePolicy.title}}</a>
            <a class="link" target="_blank" *ngIf="termsOfUse.URL" [attr.href]="termsOfUse.URL">{{termsOfUse.title}}</a>
          </div>
          <p></p>
        </form>

        <!-- </ruf-card> -->
        <div class="cls-container-footer">
          <div style="text-align: center">
            <img src="https://login6.fisglobal.com/idp/implement/files/eloginuat/FISAuthenticator_Logo.png" height="50" width = "50" margin-left="auto" margin-right="auto">
          </div>
          <p></p>
          <div class="cls-container-footer-disclaimer">{{disclaimer}}</div>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="footer-div">
    <div class="footer">
      <div class="footer-registration-mark">{{registrationMark}}</div>
      <div class="footer-version-data">{{versionData}}</div>
    </div>
  </div>
</div>
<!-- </div> -->
<!-- </ruf-app-canvas> -->
<!-- </div> -->
