import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LoginService } from '../services/login.service';
import { RedirectService } from '../services/redirect.service';
import { DataService } from '../../../../../../libs/ui/admin-ui/src/lib/data.service';
import * as qs from 'qs';
import { DEFAULT_TRANSLATIONS, IProduct } from '@common/src/default-translation';
import { ProductService } from '../services/product.service';
import { ThemeService } from '../services/theme.service';
import { AppVisibilityService } from './../services/app-visibility.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ruf-loginflow',
  templateUrl: './loginflow.component.html',
  styleUrls: ['./loginflow.component.scss'],
})
export class LoginflowComponent implements OnInit, OnDestroy {
  entityId: any;
  userName: any;
  idpRedirectUrl: any;
  errorVisible = false;
  errorDetails: any[] = [];
  loginForm: FormGroup;
  isSubmitted = false;
  clientIdToRedirect = undefined;
  productName: any;
  displayProduct: boolean;
  privacyPolicy = { title: '', URL: '' };
  cookiePolicy = { title: '', URL: '' };
  termsOfUse = { title: '', URL: '' };
  disclaimer = '';
  registrationMark = '';
  versionData = '';
  awaitingResponse: boolean = false;
  private allowedQueryParams: string[] = ['clientid', 'relaystate'];
  languageCodes = null;
  translations = null;
  selectedLangCode = 'en';
  selectedTranslation = null;
  loginFormTitle = '';
  entityOrFirmFieldTitle = '';
  usernameFieldTitle = '';
  proceedText = '';
  remember = false;
  rememberUserId: '';
  loginCookieInfo: '';
  loginDisclaimer = '';
  product: Promise<IProduct>;
  productSubscription: Subscription;

  constructor(
    private opentab: RedirectService,
    private loginSvc: LoginService,
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) private document: Document,
    private recaptchaV3Service: ReCaptchaV3Service,
    private productService: ProductService,
    private themeService: ThemeService,
    private appVisibilityService: AppVisibilityService
  ) {}

  title = 'Central Login Page';

  ngOnInit(): void {
    this.productSubscription = this.productService.current$.subscribe(
      (product) => {
        if (product !== null) {
          this.processProduct(product);
          this.appVisibilityService.updateVisibility(product);
        }
      }
    );

    this.awaitingResponse = true;
    this.hideCaptcha();
    this.loginForm = this.formBuilder.group({
      txtEntityId: ['', [Validators.required]],
      txtUsername: ['', [Validators.required]],
    });

    // Check if entity ID and username are cached in local storage
    if(localStorage.getItem("entityId") !== null && localStorage.getItem("userName") !== null) {
      this.entityId = localStorage.getItem("entityId");
      this.userName = localStorage.getItem("userName");
    }
  }

  /**
   * Receives product data and sets it to local variables in loginflow.
   *
   * @param product the product to be processed
   */
  private processProduct(product: IProduct) {
    try {
      if (product !== null && product !== undefined) {
        this.displayProduct = true;
        this.productName = product.name;
        this.privacyPolicy.URL = product.privacyPolicyUrl
          ? product.privacyPolicyUrl
          : '';
        this.cookiePolicy.URL = product.cookiePolicyUrl
          ? product.cookiePolicyUrl
          : '';
        this.termsOfUse.URL = product.termsOfUseUrl
          ? product.termsOfUseUrl
          : '';
        if (product.queryParams.length > 0) {
          this.allowedQueryParams = product.queryParams;
        }
        this.disclaimer = product.disclaimer;
        this.registrationMark = product.registrationMark;
        this.versionData = product.versionData;
        this.translations = product.translations;
        this.languageCodes = product.supportedLanguages;
        const langCodeFromCookie = this.getLangPreferenceCookie();
        const langCode = langCodeFromCookie
          ? langCodeFromCookie
          : navigator.language;
        this.setTranslation(langCode);
      }
    } catch (err) {
      this.errorVisible = true;
      this.errorDetails.push({
        message:
          'Server Error ! The Site Is Experiencing Technical Difficulties.',
      });
    }
    this.awaitingResponse = false;
  }

  /**
   * Determines whether product is ready to be displayed to the page.
   *
   * @returns true if ready to be displayed, false otherwise
   */
  displayProductToPage(): boolean {
    return this.appVisibilityService.isVisible();
  }

  /**
   * Sets login page text to chosen translation.
   * @param chosenLang Code corresponding to the selected language.
   */
  setTranslation(chosenLang: string): void {
    if (this.languageCodes.includes(chosenLang)) {
      this.selectedLangCode = chosenLang;
    } else {
      //Default to prefix language if exact tag not supported ex: "fr-ca" defaults to "fr" if not found.
      const langCodePrefix = chosenLang.split('-')[0];
      this.selectedLangCode = this.languageCodes.includes(langCodePrefix)
        ? langCodePrefix
        : 'en';
    }

    // Use default translation if chosen lang is not supported.
    this.selectedTranslation = this.translations[this.selectedLangCode]
      ? this.translations[this.selectedLangCode]
      : DEFAULT_TRANSLATIONS.en;
    const translation = this.selectedTranslation;

    // Set localized text for form elements
    this.loginFormTitle = translation.login_form_title
      ? translation.login_form_title
      : DEFAULT_TRANSLATIONS.en.login_form_title;
    this.entityOrFirmFieldTitle = translation.entity_field_title
      ? translation.entity_field_title
      : DEFAULT_TRANSLATIONS.en.entity_field_title;
    this.usernameFieldTitle = translation.username_field_title
      ? translation.username_field_title
      : DEFAULT_TRANSLATIONS.en.username_field_title;
    this.rememberUserId = translation.remember_user_id
      ? translation.remember_user_id
      : DEFAULT_TRANSLATIONS.en.remember_user_id;
    this.loginCookieInfo = translation.login_page_cookie_info
      ? translation.login_page_cookie_info
      : DEFAULT_TRANSLATIONS.en.login_page_cookie_info;
    this.proceedText = translation.proceed_btn
      ? translation.proceed_btn
      : DEFAULT_TRANSLATIONS.en.proceed_btn;

    // Set localized text for policy links
    this.privacyPolicy.title = translation.privacy_policy
      ? translation.privacy_policy
      : DEFAULT_TRANSLATIONS.en.privacy_policy;
    this.cookiePolicy.title = translation.cookie_policy
      ? translation.cookie_policy
      : DEFAULT_TRANSLATIONS.en.cookie_policy;
    this.termsOfUse.title = translation.terms_of_use
      ? translation.terms_of_use
      : DEFAULT_TRANSLATIONS.en.terms_of_use;

    // Set localized text for login disclaimer
    this.loginDisclaimer = translation.login_disclaimer
      ? translation.login_disclaimer
      : DEFAULT_TRANSLATIONS.en.login_disclaimer;
  }

  /**
   * Gets the language code from stored cookie.
   * @returns A sting containing a language code or an empty string if none found.
   */
  getLangPreferenceCookie(): string {
    const cookies = document.cookie.split(';');
    const langCookie = cookies.find((c) => c.startsWith('language='));
    if (langCookie) {
      const langCodeFromCookie = langCookie.split('=')[1] || '';
      return langCodeFromCookie;
    }
    return '';
  }

  /**
   * Saves the chosen language code as a cookie.
   * @param langCode Language code to set in cookie.
   */
  setLangPreferenceCookie(langCode: string): void {
    const expireDate = new Date();
    expireDate.setUTCFullYear(expireDate.getUTCFullYear() + 10);
    const cookie =
      'language=' +
      langCode +
      ';' +
      'expires=' +
      expireDate.toUTCString() +
      ';';
    this.document.cookie = cookie;
  }

  handleValidation() {
    this.errorDetails = [];
    this.errorVisible = false;
    if (this.entityId === undefined || this.entityId === '') {
      this.errorVisible = true;
      this.errorDetails.push({ message: 'EntityID or Firm Name is required' });
    }
    if (this.userName === undefined || this.userName === '') {
      this.errorVisible = true;
      this.errorDetails.push({ message: 'Username is required' });
    }
  }

  async next() {
    let token;
    this.isSubmitted = true;
    this.awaitingResponse = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.awaitingResponse = false;
      return;
    } else {
      try {
        token = await this.recaptchaV3Service.execute('submit').toPromise();
      } catch (error) {
        throw new Error('Failed to connect to captcha Service');
      }

      if (token) {
        const loginData = {
          entityId: this.entityId,
          userName: this.userName,
        };
        // Store entity ID and username in local storage if Remember my User ID is checked
        if(this.remember) {
          localStorage.setItem('entityId', this.entityId);
          localStorage.setItem('userName', this.userName);
        }
        try {
          this.loginSvc.fetchLoginUrl(loginData, token).subscribe(
            (resp) => {
              if (
                resp !== null &&
                resp !== undefined &&
                resp.url !== undefined
              ) {
                this.hideCaptcha();
                const paramString = this.buildParamString();
                const redirectUrlWithParams = resp.url + paramString;
                const redirectUrl = redirectUrlWithParams + (this.clientIdToRedirect !== undefined ? '?ClientID=' + this.clientIdToRedirect : '');
                this.redirect(redirectUrl);
              }
              else {
                this.errorVisible = true;
                this.errorDetails.push({
                  message:
                    'Server Error ! The Site Is Experiencing Technical Difficulties.',
                });
              }
            },
            (error) => {
              this.errorVisible = true;
              this.errorDetails.push({
                message:
                  'Server Error ! The Site Is Experiencing Technical Difficulties.',
              });
              this.hideCaptcha();
            }
          );
        }
        catch (err) {
          this.errorVisible = true;
          this.errorDetails.push({
            message:
              'Server Error ! The Site Is Experiencing Technical Difficulties.',
          });
        }
      }
    }
    this.handleValidation();
    this.awaitingResponse = false;
  }

  /**
   * Filters given params down to only allowed params
   * then formats params as a URL segment for redirectURL.
   * @returns formatted query params.
   */
  private buildParamString(): string {
    function fromEntries<T>(entries: [keyof T, T[keyof T]][]): T {
      return entries.reduce((acc, [key, value]) => ({ ...acc, [key]: value }),<T>{});
    }

    let paramString = '';
    const allowedParams = this.allowedQueryParams.map(v => v.toLowerCase());
    const query = window.location.search;
    if (query.length > 1) {
      const parsedQS = qs.parse(query.slice(1));
      const params = Object.entries(parsedQS);
      const sanitizedQueryParams = fromEntries(params.filter(kvp => allowedParams.includes(kvp[0].toLowerCase())));

      paramString = qs.stringify(sanitizedQueryParams, {encode: false, indices: false});
      paramString = paramString.length > 1 ? '?' + paramString : '';
    }

    return paramString;
  }

  redirect(response: any) {
    let param = { userName: this.userName };
    this.opentab.post(param, response);
  }

  closeError() {
    this.errorVisible = false;
  }

  showCaptcha() {
    this.document.body.classList.add('recaptcha');
  }

  hideCaptcha() {
    this.document.body.classList.remove('recaptcha');
  }

  toLower(params: any): any {
    const lowerParams: any = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }
    return lowerParams;
  }

  ngOnDestroy() {
    this.productSubscription.unsubscribe();
  }
}
