// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const baseUrl = 'https://signin-dev.fiscloudservices.com';
export const environment = {
  production: false,
  env: 'development',
  loginDetailsUrl: `${baseUrl}/api/v1/cls/getfirmurl`,
  reCaptchaSiteKey: '6LcwlvseAAAAALVY-PxOY37KWA9SnmLu6YXkWNSd',
  auth: {
    issuer: 'https://infinity.dev.fiscloudservices.com/idp/CentralLandingSite',
    clientId: 'CentralLandingSite',
    dummyClientSecret: 'ac7bd2430f2b5045680aff60c1bd18bde84b',
    idpApiKey: 'CentralLandingSite',
    redirectUri: `${baseUrl}/admin`,
    logoutUrl: `${baseUrl}/admin`,
    requireHttps: false,
    waitForTokenInMsec: 1000,
    authorizationClaimProperty: 'CLSAuthorizations',
    interceptUrls: [
      `${baseUrl}/api/v1/products`,
      `${baseUrl}/api/v1/themes`,
      `${baseUrl}/api/v1/config`,
      `${baseUrl}/api/v1/logs`,
    ],
    unauthorizedRoute: ['/not-authorized'],
    autoRefresh: false,
    skipIssuerCheck: true,
  },
  apis: {
    homePageUrl: `${baseUrl}`,
    apiBaseUrl: `${baseUrl}/_`,
    registrationUrl: `${baseUrl}/registration`,
    countryCodesUrl: `${baseUrl}/country-codes`,
    activatedProductUrl: `${baseUrl}/products/activated`,
    admin: {
      productUrl: `${baseUrl}/api/v1/products`,
      themeUrl: `${baseUrl}/api/v1/themes`,
      configUrl: `${baseUrl}/api/v1/config`,
      logsUrl: `${baseUrl}/api/v1/logs`,
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
