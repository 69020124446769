import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { isURL } from 'class-validator';

export const idpUrlExp = new RegExp('.*({idpUrl}).*');
export const kebabCase = (str) =>
  str
    .replace(/([a-z])([A-Z0-9])/g, '$1-$2')
    .replace(/([0-9])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();

export function jsonValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && control.value.length) {
      try {
        JSON.parse(control.value);
        return null;
      } catch (e) {
        return { invalidJson: 'Please enter valid json' };
      }
    }
    return null;
  };
}

export function fillFormErrors(err: any, form: FormGroup) {
  if (err.error && err.error.message && err.error.message.map) {
    const remaining = [];
    const errs = err.error.message.map((m) => ({
      prop: m.property,
      msgs: Object.values(m.constraints),
    }));

    for (const { prop, msgs } of errs) {
      const fieldError = msgs.join(', ');

      const field = form.get(prop);
      if (field) {
        field.setErrors({ server: fieldError });
      } else {
        remaining.push(fieldError);
      }
    }

    return remaining.length > 0 && remaining;
  }

  return false;
}

export function isValidUrl(url: string) {
  return url && isURL(url);
}

export const WhiteSpaceRegExp = '(.|\\s)*\\S(.|\\s)*';

export const EmailRegExp = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w+)+$';
