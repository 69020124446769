import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'ruf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Central Login Page';

  ngOnInit(): void {}
  // get theme() {
  //   return (this.currentProduct && this.currentProduct.theme) || 'fis';
  // }

  // get currentProduct() {
  //   return this.currentProduct;
  // }

  // set currentProduct(value) {
  //   this.currentProduct = value;
  // }

  // constructor(
  //   // private route: ActivatedRoute,
  //   // private appSvc: AppService,
  //   @Inject(DOCUMENT) private doc
  // ) {}

  // ngOnInit(): void {
  //   const data = {
  //                   activated: true,
  //                   disableCaptcha: true,
  //                   homeUrl: '',
  //                   loginUrl: '',
  //                   name: 'SignIn',
  //                   loginFields: [],
  //                   supportUrl: null,
  //                   supportedLanguages: ["en"],
  //                   theme: "black",
  //                   title: "Central Login",
  //                   translations: {},
  //                   troubleshootingUrl: '',
  //                   _id: "signin",
  //                 };
  //   if (data) {
  //     this.currentProduct = data['product'];
  //   }
  //   const link = this.doc.createElement('link');
  //   link.setAttribute('rel', 'stylesheet');
  //   link.setAttribute('href', `/api/_/themes/${this.theme}/theme.css`);
  //   this.doc.head.appendChild(link);
  // }
}
