import { ProductService } from 'apps/ui/central-login-ui/src/app/services/product.service';
import { IProduct } from '@common/src/default-translation';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class ThemeService implements OnDestroy {
  productSubscription: Subscription;

  constructor(
    private productService: ProductService
  ) {
    this.productSubscription = this.productService.current$.subscribe(
      (product) => {
        if (product !== null) {
          this.paintTheme(product);
        }
      }
    );
  }

  /**
   * Paints the theme when product is loaded and not null. If product has no data, the default theme is painted.
   *
   * @param product the product to be evaluated to determine the painted theme
   */
  async paintTheme(product: IProduct): Promise<void> {
    if (product !== null) {
      const link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute(
        'href',
        `/api/v1/themes/${product.theme}/theme.css?r=1`
      );
      document.head.appendChild(link);
    }
  }

  ngOnDestroy() {
    this.productSubscription.unsubscribe();
  }
}
