export interface ITheme {
  _id: any;
  name: string;
  logo: string;
  topGradient: string;
  bottomGradient: string;
  primary100: string;
  primary: string;
  primary700: string;
  primary100Contrast: string;
  primaryContrast: string;
  primary700Contrast: string;
  accent100: string;
  accent: string;
  accent700: string;
  accent100Contrast: string;
  accentContrast: string;
  accent700Contrast: string;
  emphasis100: string;
  emphasis: string;
  emphasis700: string;
  emphasis100Contrast: string;
  emphasisContrast: string;
  emphasis700Contrast: string;
  warn100: string;
  warn: string;
  warn700: string;
  warn100Contrast: string;
  warnContrast: string;
  warn700Contrast: string;
  createdBy: string;
  updatedBy: string;
  showProduct: boolean;
  productName: string;
  hideBackgroundImage: boolean;
}
