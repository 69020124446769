export interface IRegistrationField {
  id: string;
  type?: string;
  label: string;
  hint?: string;
  required: boolean;
  options?: { value: string; label: string; selected?: boolean }[];
  min?: number;
  max?: number;
  maxLength?: number;
  pattern?: string;
  validationMessages?: { [name: string]: string };
  unlocked?: boolean;
}

export interface IProduct {
  name: string;
  supportedLanguages?: string[];
  prodUrlMapping: string[];
  queryParams?: string[];
  contactEmail: string[];
  emailDomains?: string[];
  customFields?: { [name: string]: string };
  registrationFields: IRegistrationField[];
  activated: boolean;
  theme: string;
  themeCacheId: string;
  homeUrl: string;
  loginUrl: string;
  troubleshootingUrl: string;
  supportUrl?: string;
  icUrl?: string;
  privacyPolicyUrl: string;
  cookiePolicyUrl: string;
  termsOfUseUrl: string;
  disclaimer: string;
  registrationMark: string;
  versionData: string;
  disableCaptcha?: boolean;
  title: string;
  translations: { [key: string]: { [key: string]: string } };
  headerText: string;
  isLoggingEnabled: boolean;
  createdBy: string;
  updatedBy: string;
}

export interface IRegistration {
  product: IRegistrationProduct;
  fields: IRegistrationFields;
  reCaptchaToken: string;
  context?: { [name: string]: string };
}

export interface IRegistrationProduct {
  id: string;
  name?: string;
}

export interface IRegistrationFields {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  [name: string]: string | number;
}
