import { Injectable } from '@angular/core';
import { ApiService } from './shared';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private _apiService: ApiService) {}

  public fetchLoginUrl(payload: any, reCaptchaToken: any): Observable<any> {
    //http://localhost:3333/api/cls/getfirmurl?clientId=sungard
    const url = environment.loginDetailsUrl + '?clientId=' + payload.entityId;
    return this._apiService.getWithCustomHeader<any>(url, reCaptchaToken);
  }
}
