import { WhiteSpaceRegExp, EmailRegExp } from './utils';

export const FIELD_TYPE_TEXT = 'text';
export const FIELD_TYPE_NUMBER = 'number';
export const FIELD_TYPE_SELECT_ONE = 'selectOne';
export const FIELD_TYPE_SELECT_MANY = 'selectMany';
export const FIELD_TYPE_CHECKBOX = 'checkbox';
export const FIELD_TYPE_EMAIL = 'email';

export const DEFAULT_REGISTRATION_FIELDS = [
  {
    id: 'firstName',
    label: 'First Name',
    type: 'text',
    required: true,
    pattern: WhiteSpaceRegExp,
    validationMessages: {
      required: 'Required',
      pattern: 'EmptySpaceValidationError',
    },
    unlocked: false,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    type: 'text',
    required: true,
    pattern: WhiteSpaceRegExp,
    validationMessages: {
      required: 'Required',
      pattern: 'EmptySpaceValidationError',
    },
    unlocked: false,
  },
  {
    id: 'email',
    label: 'Email',
    type: 'email',
    required: true,
    pattern: EmailRegExp,
    validationMessages: {
      required: 'Required',
      pattern: 'EmailValidationError',
    },
    unlocked: false,
  },
  {
    id: 'mobile',
    label: 'Mobile Number',
    type: 'tel',
    required: true,
    pattern: WhiteSpaceRegExp,
    validationMessages: {
      required: 'Required',
      pattern: 'EmptySpaceValidationError',
    },
    unlocked: false,
  },
];
