<!-- <div fxLayout="column" [style.height]="'100vh'">
  <ruf-app-canvas fxFlex class="card-example-canvas clp-canvas" [style.overflow-y]="'auto'">
    <div class="product-branding">
      <div class="logo"></div>
      <ng-container>
        <ng-container *rufIfGtXs="true">
          <div class="seperator"></div>
        </ng-container>
      </ng-container>
    </div>
    <router-outlet></router-outlet>
    <div class="footer-div">
      <div class="footer">
        © 2022 FIS. Advancing the way the world pays, banks and invests <a href="https://www.fisglobal.com/privacy"
          target="__blank" class="footer-link">Privacy Policy</a>
      </div>
    </div>
  </ruf-app-canvas>
</div> -->


<router-outlet></router-outlet>