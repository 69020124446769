import { Subscription } from 'rxjs';
import { ProductService } from 'apps/ui/central-login-ui/src/app/services/product.service';
import { Injectable, OnDestroy } from "@angular/core";
import { IProduct } from 'common/src/product.interface';

@Injectable()
export class AppVisibilityService implements OnDestroy {
  visible = false;
  productSubscription: Subscription;

  constructor(
    private productService: ProductService,
  ) {
    this.productSubscription = this.productService.current$.subscribe(
      (product) => {
        if (product !== null) {
          this.updateVisibility(product);
        }
      }
    );
  }

  /**
   * Updates the visibility of the app based on whether product is loaded
   *
   * @param product the product to be assessed
   * @returns true if product is loaded, false otherwise
   */
  updateVisibility(product: IProduct) {
    if (product !== null) {
      this.visible = true;
    }
    else {
      this.visible = false;
    }
  }

  /**
   * Returns the value relating to the app's current visibility
   * @returns true if app can be displayed false otherwise
   */
  isVisible(): boolean {
    return this.visible;
  }

  ngOnDestroy() {
    this.productSubscription.unsubscribe();
  }
}
