import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { IProduct } from '@common/src/default-translation';
import { DataService } from 'libs/ui/admin-ui/src/lib/data.service';

@Injectable()
export class ProductService {
  current$: BehaviorSubject<IProduct | null> = new BehaviorSubject(null);

  constructor(
    private dataService: DataService,
    @Inject(DOCUMENT) private document: Document
    ) {
    this.fetchProduct(document.location.hostname);
  }

  /**
   * Fetches product from the hostname and returns all product data
   *
   * @param productUrlMap the mapping where the product is fetched from
   * @returns the resulting product to be loaded
   */
  async fetchProduct(productUrlMap: string): Promise<IProduct> {
    return this.dataService
        .fetchProdUrlMapping(productUrlMap)
        .then((product) => {
          if (product !== null && product !== undefined) {
            this.current$.next(product);
            return product;
          }
        });
  }
}
